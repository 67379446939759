import { baseUrl } from '@/service/baseUrl.js'
export default {
    data() {
        return {
            alipayHost: process.env.VUE_APP_GATEWAY,
            voucherInput: {},
            type: undefined
        }
    },
    methods: {
        open(voucherInput, type) {
            this.type = type
            this.voucherInput = voucherInput;
            setTimeout(() => {
                this.$refs.aliPay.show();
            }, 10);            
        },
        closeAlipay() {
            this.$refs.aliPay.hide();
          },
        completeAlipay() {
            if (this.type === 'ele') {
                this.$router.push({ path: '/elePayDetail' });
            } else {
                this.$router.push({
                    path: "/alipayResult"
                });
            }
        }         
    }
}