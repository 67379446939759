import {
    miceService
} from "@/service/miceService.js";
import {
    mapGetters
} from 'vuex'
import {
    SmartStorage
} from 'smart-core-util'
import { Colors } from '@/utils/colors.js'
export default {
    data() {
        return {
            navBackgroundColor: {}
        }
    },
    computed: {
        ...mapGetters([
            'event'
        ])
    },
    mounted() {
        this.navBackgroundColor = Colors
    },
    methods: {
        //打开美团外卖
        openTakeoutDianPing(params, callback) {
            let params1 = {
                itemId: params.itemId
            }
            let open = null
            if (SmartStorage.get("isH5")) {
                open = window.open("", "_blank")
            }
            miceService.getDianPingUrl(params1).then(res => {
                if (res && res.success) {
                    if (SmartStorage.get("isH5")) {
                        setTimeout(() => {
                            open.location = res.content
                        }, 100)
                        return
                    }
                    const tenant_code = this.event.tenant_code || 'eventcool';
                    const postData = {
                        loadUrl: res.content,
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    }
                    this.openNewWebSiteForResult(postData, callback)
                }
            })
        },
        //打开美团餐
        async openMealDianPing(params, callback) {
            miceService.mealDianPingUrl(params).then(res => {
                if (res && res.success) {
                    const tenant_code = this.event.tenant_code || 'eventcool';
                    const postData = {
                        loadUrl: res.content,
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    }
                    this.openNewWebSiteForResult(postData, callback)
                }
            })
        },

        // 打开饿了么
        openTakeoutEle(url, callback) {
            const tenant_code = this.event.tenant_code || 'eventcool';
            const postData = {
                loadUrl: url,
                browserMode: {
                    navBarVisibilty: true,
                    titleTextColor: "#ffffff",
                    navBackgroundColor: this.navBackgroundColor[tenant_code]
                }
            }
            this.openNewWebSiteForResult(postData, callback)
        },

        openNewWebSiteForResult(postData, callback) {
            if(this.event.dataCode || SmartStorage.get("isH5")) {
                window.location.href = postData.loadUrl
            } else {
                const options = {
                    method: "SMGeneral.openNewWebSiteForResult",
                    postData: postData,
                    callback: async () => {
                        callback && callback();
                    }
                };
                this.iJsBridge.call(options);
            }
        }
    }
}